import {AllReasons, Reason, ReasonDescription, ReasonHeader, ReasonsContainer, ReasonsHeader} from "./styles";

function Reasons() {
  return (
    <ReasonsContainer>
      <ReasonsHeader>Waarom een website laten maken?</ReasonsHeader>

      <AllReasons>
        <Reason>
          <svg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox="0 0 24 24"><title>sun_line</title>
            <g id="sun_line" fill='none' fill-rule='nonzero'>
              <path
                d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z'/>
              <path fill='#007AFFFF'
                    d='M12 19a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1Zm6.364-2.05.707.707a1 1 0 0 1-1.414 1.414l-.707-.707a1 1 0 0 1 1.414-1.414Zm-12.728 0a1 1 0 0 1 1.497 1.32l-.083.094-.707.707a1 1 0 0 1-1.497-1.32l.083-.094.707-.707ZM12 6a6 6 0 1 1 0 12 6 6 0 0 1 0-12Zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-8 3a1 1 0 0 1 .117 1.993L4 13H3a1 1 0 0 1-.117-1.993L3 11h1Zm17 0a1 1 0 1 1 0 2h-1a1 1 0 1 1 0-2h1ZM4.929 4.929a1 1 0 0 1 1.32-.083l.094.083.707.707a1 1 0 0 1-1.32 1.497l-.094-.083-.707-.707a1 1 0 0 1 0-1.414Zm14.142 0a1 1 0 0 1 0 1.414l-.707.707a1 1 0 1 1-1.414-1.414l.707-.707a1 1 0 0 1 1.414 0ZM12 2a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1Z'/>
            </g>
          </svg>
          <ReasonHeader>Professionele Uitstraling</ReasonHeader>
          <ReasonDescription>Een aantrekkelijke website straalt professionaliteit uit en wekt vertrouwen bij potentiële
            klanten.</ReasonDescription>
        </Reason>

        <Reason>
          <svg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox="0 0 24 24">
            <title>eye_2_line</title>
            <g id="eye_2_line" fill='none' fill-rule='evenodd'>
              <path
                d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z'/>
              <path fill='#007AFFFF'
                    d='M4 12.001V12c.003-.016.017-.104.095-.277.086-.191.225-.431.424-.708.398-.553.993-1.192 1.745-1.798C7.777 7.996 9.812 7 12 7c2.188 0 4.223.996 5.736 2.216.752.606 1.347 1.245 1.745 1.798.2.277.338.517.424.708.078.173.092.261.095.277V12c-.003.016-.017.104-.095.277-.086.191-.225.431-.424.708-.398.553-.993 1.192-1.745 1.798C16.224 16.004 14.189 17 12 17c-2.188 0-4.223-.996-5.736-2.216-.752-.606-1.347-1.245-1.745-1.798a4.226 4.226 0 0 1-.424-.708A1.115 1.115 0 0 1 4 12.001ZM12 5C9.217 5 6.752 6.254 5.009 7.659c-.877.706-1.6 1.474-2.113 2.187-.257.356-.471.713-.625 1.055C2.123 11.23 2 11.611 2 12c0 .388.123.771.27 1.099.155.342.37.7.626 1.055.513.713 1.236 1.48 2.113 2.187C6.752 17.746 9.217 19 12 19c2.783 0 5.248-1.254 6.991-2.659.877-.706 1.6-1.474 2.113-2.187.257-.356.471-.713.625-1.055.148-.328.271-.71.271-1.099 0-.388-.123-.771-.27-1.099a6.197 6.197 0 0 0-.626-1.055c-.513-.713-1.236-1.48-2.113-2.187C17.248 6.254 14.783 5 12 5Zm-1 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z'/>
            </g>
          </svg>
          <ReasonHeader>Bereik en Zichtbaarheid</ReasonHeader>
          <ReasonDescription>Vergroot je bedrijfsbereik en zichtbaarheid door online aanwezig te zijn, waardoor
            potentiële klanten je gemakkelijker kunnen vinden.</ReasonDescription>
        </Reason>

        <Reason>
          <svg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox="0 0 24 24">
            <title>happy_line</title>
            <g id="happy_line" fill='none' fill-rule='evenodd'>
              <path
                d='M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z'/>
              <path fill='#007AFFFF'
                    d='M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm6.5-2c-.195 0-.444.124-.606.448a1 1 0 0 1-1.788-.896C6.542 8.68 7.413 8 8.5 8c1.087 0 1.957.68 2.394 1.552a1 1 0 0 1-1.788.896C8.944 10.124 8.696 10 8.5 10Zm7 0c-.195 0-.444.124-.606.448a1 1 0 1 1-1.788-.896C13.543 8.68 14.413 8 15.5 8c1.087 0 1.957.68 2.394 1.552a1 1 0 0 1-1.788.896c-.162-.324-.41-.448-.606-.448Zm-6.896 4.338a1 1 0 0 1 1.412-.088c.53.468 1.223.75 1.984.75.761 0 1.455-.282 1.984-.75a1 1 0 1 1 1.324 1.5A4.984 4.984 0 0 1 12 17a4.984 4.984 0 0 1-3.308-1.25 1 1 0 0 1-.088-1.412Z'/>
            </g>
          </svg>
          <ReasonHeader>Marketing en Klantbetrokkenheid</ReasonHeader>
          <ReasonDescription>Versterk je merk met een website als krachtig marketinginstrument, bevorder interactie en
            stimuleer klantloyaliteit.</ReasonDescription>
        </Reason>
      </AllReasons>
    </ReasonsContainer>
  )
}

export default Reasons;