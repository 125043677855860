import styled from "styled-components";

export const FeaturesContainer = styled.div`
  padding: 200px 0 100px 0;
  width: 100%;
  
  background-image: url("https://firebasestorage.googleapis.com/v0/b/kouhaidesign-c8924.appspot.com/o/images%2Fvector_1.webp?alt=media&token=a2fd3742-2645-496d-8494-35a3d2781528");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const FeaturesHeader = styled.h2`
  margin-bottom: 75px;
  font-size: 36px;
  color: white;
  text-align: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const AllFeatures = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: unset;
    align-items: center;
  }
`;

export const Feature = styled.div`
  max-width: 45%;
  margin: 16px 2.5%;
  word-break: break-word;

  @media only screen and (max-width: 768px) {
    text-align: center;
    max-width: 90%;
  }
`;

export const FeaturePlus = styled.span`
  color: #007AFF;
`;

export const FeatureHeader = styled.h3`
  font-size: 24px;
  color: white;
  margin-bottom: 16px;
`;

export const FeatureDescription = styled.p`
  font-size: 16px;
  color: white;

`;