import styled from "styled-components";

export const ProcessContainer = styled.div`
  width: 100%;
  padding: 0 0 50px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding: 32px 16px;
  }
`;

export const ProcessesHeader = styled.h2`
  margin-bottom: 16px;
  font-size: 36px;
`;


export const ProcessesDescription = styled.p`
  margin-bottom: 50px;
  font-size: 20px;
  width: 50%;

  @media only screen and (max-width: 768px) {
    width: 80%
  }
`;

export const AllProcesses = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    width: 80%;
  }
`;

export const Process = styled.div`
  aspect-ratio : 1 / 1;
  width: 30%;
  min-width: 200px;
  padding: 16px;
  
  border-radius: 50%;
  border: 5px dashed lightgrey;
  
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ProcessTitle = styled.h2`
  font-size: 24px;
  margin-top: 16px;
`;