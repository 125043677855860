export const plans = [
  {
    name: "Landingpagina",
    description: "Maak een indrukwekkende eerste kennismaking met je doelgroep met een mooie landingpagina voor jouw " +
      "bedrijf.",
    cost: "Vanaf €250,-",
    features: [
      "Levertijd: 1 tot 2 weken",
      "1 pagina",
      "Unieke website, geen template",
      "SEO optimalisatie",
      "Laadtijd optimalisatie",
      "Responsive design",
      "Contactformulier",
      "Hulp met hosten op domein",
      "1 maand gratis onderhoud",
    ],
    optionals: [
      {
        name: "Extra pagina's: ",
        cost: "€25 per pagina"
      },
      {
        name: "Complete ondersteuning bij het opzetten en hosten van jouw domein en website: ",
        cost: "€5 p/m"
      },
      {
        name: "Website onderhouding: ",
        cost: "€50 p/m"
      }
    ]
  },
  // {
  //   name: "Webshop",
  //   description: "Een compleet pakket voor het ontwerpen van een gebruiksvriendelijke en visueel aantrekkelijke " +
  //     "online winkel, afgestemd op jouw unieke merk en producten.",
  //   cost: "Vanaf €800,-",
  //   features: [
  //     "Levertijd: minimaal 1 maand",
  //     "Unieke webshop, geen template",
  //     "SEO optimalisatie",
  //     "Responsive design",
  //     "Toegang tot analyses",
  //     "Hulp met hosten op domein",
  //     "Google Analytics",
  //     "1 maand gratis onderhoud",
  //   ],
  //   optionals: [
  //     {
  //       name: "Complete ondersteuning bij het opzetten en hosten van jouw domein en website: ",
  //       cost: "€5 p/m"
  //     },
  //     {
  //       name: "Website onderhouding: ",
  //       cost: "€150 p/m"
  //     }
  //   ]
  // }
];