import styled from "styled-components";

export const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 32px 15%;
  user-select: none;
  position: absolute;

  @media only screen and (max-width: 768px) {
    padding: 32px;
  }
`;

export const MenuTitle = styled.p`
  color: white;
  font-family: Dashing, serif;
  font-size: 30px;
`;

export const MenuItem = styled.p`
  color: white;
  font-size: 24px;
  margin-left: auto;
  cursor: pointer;
  transition: 0.2s;
  
  &:hover {
    filter: brightness(80%);
  }
`;