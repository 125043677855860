import {
  AllContactInfo,
  ContactContainer,
  ContactForm,
  ContactInfo, ContactInfoField,
  ContactSectionHeader, CopyRights,
  FormFreeText,
  FormInput, FullWidthButton
} from "./styles";
import {useState} from "react";

function Contact({contactRef}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const sendMessage = () => {
    const validEmail = emailRegex.test(email);
    if (validEmail && name.length > 0 && message.length > 0) {
      fetch("https://kouhaidesign.kouhai.workers.dev/", {
        method: "POST",
        body: JSON.stringify({
          "name": name,
          "email": email,
          "message": message
        })
      }).then(res => res.text()).then(resText => {
        if (resText === "true") alert("Dank je wel! We hebben je bericht ontvangen. We nemen spoedig contact met je op.");
        else alert("Oeps! Er is iets misgegaan bij het verwerken van je inzending. Probeer het later opnieuw of neem contact op via e-mail.")
      });
    }
    else alert("Vul alle velden in en zorg ervoor dat de informatie correct is ingevuld.");
  };

  return (
    <ContactContainer ref={contactRef}>
      <AllContactInfo>
        <ContactForm>
          <ContactSectionHeader>Stuur een bericht</ContactSectionHeader>
          <FormInput
            placeholder="Jouw naam"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormInput
            placeholder="E-mailadres"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormFreeText
            placeholder="Vertel ons meer over jouw ideeën en wensen voor de perfecte landingpagina..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <FullWidthButton onClick={sendMessage}>Verzenden</FullWidthButton>
        </ContactForm>

        <ContactInfo>
          <ContactSectionHeader>Contactgegevens</ContactSectionHeader>

          <ContactInfoField>
            <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox="0 0 24 24">
              <title>phone_line</title>
              <g id="phone_line" fill='none' fill-rule='evenodd'>
                <path
                  d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z'/>
                <path fill='#FFFFFFFF'
                      d='M8.172 15.829c3.845 3.845 7.408 4.266 8.454 4.305 1.264.046 2.554-.986 3.112-2.043-.89-1.044-2.049-1.854-3.318-2.732-.749.748-1.672 2.138-2.901 1.64-.699-.281-2.425-1.076-3.933-2.585C8.077 12.906 7.283 11.18 7 10.482c-.498-1.231.896-2.156 1.645-2.905-.878-1.29-1.674-2.479-2.716-3.324-1.072.56-2.11 1.84-2.063 3.121.039 1.046.46 4.609 4.306 8.455Zm8.38 6.304c-1.44-.053-5.521-.617-9.795-4.89-4.273-4.274-4.836-8.354-4.89-9.795-.08-2.196 1.602-4.329 3.545-5.162a1.47 1.47 0 0 1 1.445.159c1.608 1.173 2.717 2.95 3.67 4.342A1.504 1.504 0 0 1 10.35 8.7l-1.356 1.357C9.309 10.752 9.95 11.95 11 13c1.05 1.05 2.248 1.691 2.944 2.006l1.355-1.356a1.503 1.503 0 0 1 1.918-.171c1.42.984 3.088 2.077 4.304 3.634a1.47 1.47 0 0 1 .189 1.485c-.837 1.953-2.955 3.616-5.158 3.535Z'/>
              </g>
            </svg>
            <p>06 19030907</p>
          </ContactInfoField>

          <ContactInfoField>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox="0 0 24 24"><title>mail_line</title>
              <g id="mail_line" fill='none' fill-rule='nonzero'>
                <path
                  d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z'/>
                <path fill='#FFFFFFFF'
                      d='M20 4a2 2 0 0 1 1.995 1.85L22 6v12a2 2 0 0 1-1.85 1.995L20 20H4a2 2 0 0 1-1.995-1.85L2 18V6a2 2 0 0 1 1.85-1.995L4 4h16Zm0 3.414-6.94 6.94a1.5 1.5 0 0 1-2.12 0L4 7.414V18h16V7.414ZM18.586 6H5.414L12 12.586 18.586 6Z'/>
              </g>
            </svg>
            <a href="mailto: hi@kouhaisoftware.com">hi@kouhaisoftware.com</a>
          </ContactInfoField>

          <ContactInfoField>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox="0 0 24 24">
              <title>building_1_line</title>
              <g id="building_1_line" fill='none' fill-rule='nonzero'>
                <path
                  d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z'/>
                <path fill='#FFFFFFFF'
                      d='M13 3a2 2 0 0 1 1.995 1.85L15 5v4h3a2 2 0 0 1 1.995 1.85L20 11v8h1a1 1 0 0 1 .117 1.993L21 21H3a1 1 0 0 1-.117-1.993L3 19h1V5a2 2 0 0 1 1.85-1.995L6 3h7Zm5 8h-3v8h3v-8Zm-5-6H6v14h7V5Zm-2 10v2H8v-2h3Zm0-4v2H8v-2h3Zm0-4v2H8V7h3Z'/>
              </g>
            </svg>
            <p>KvK 86265555</p>
          </ContactInfoField>

          <ContactInfoField>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox="0 0 24 24">
              <title>location_line</title>
              <g id="location_line" fill='none' fill-rule='evenodd'>
                <path
                  d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z'/>
                <path fill='#FFFFFFFF'
                      d='M12 2a9 9 0 0 1 9 9c0 3.074-1.676 5.59-3.442 7.395a20.441 20.441 0 0 1-2.876 2.416l-.426.29-.2.133-.377.24-.336.205-.416.242a1.874 1.874 0 0 1-1.854 0l-.416-.242-.52-.32-.192-.125-.41-.273a20.638 20.638 0 0 1-3.093-2.566C4.676 16.589 3 14.074 3 11a9 9 0 0 1 9-9Zm0 2a7 7 0 0 0-7 7c0 2.322 1.272 4.36 2.871 5.996a18.03 18.03 0 0 0 2.222 1.91l.458.326c.148.103.29.199.427.288l.39.25.343.209.289.169.455-.269.367-.23c.195-.124.405-.263.627-.417l.458-.326a18.03 18.03 0 0 0 2.222-1.91C17.728 15.361 19 13.322 19 11a7 7 0 0 0-7-7Zm0 3a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z'/>
              </g>
            </svg>
            <p>Wethouder de Roosplein 165, 1107 AJ Amsterdam</p>
          </ContactInfoField>

        </ContactInfo>
      </AllContactInfo>

      <CopyRights>
        {`© ${new Date().getFullYear()} | Kouhai Design | `}
        <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/kouhaidesign-c8924.appspot.com/o/documents%2FAlgemene%20Voorwaarden%20(AV).pdf?alt=media&token=73d4b373-9081-4f0d-8f78-8fb990548323">Algemene voorwaarden</a>
        {" | "}
        <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/kouhaidesign-c8924.appspot.com/o/documents%2FPrivacyverklaring%20AVG.pdf?alt=media&token=e440dbe0-07ae-4433-8df2-9b850ca4a19c">Privacy verklaring</a>
      </CopyRights>
    </ContactContainer>
  );
}

export default Contact;