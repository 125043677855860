import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDnsEFezH3mXNd8nhcnwUxkBhRtwo0V9lo",
  authDomain: "kouhaidesign-c8924.firebaseapp.com",
  projectId: "kouhaidesign-c8924",
  storageBucket: "kouhaidesign-c8924.appspot.com",
  messagingSenderId: "1001013194609",
  appId: "1:1001013194609:web:fb1b8a71f033dcb72a10d0",
  measurementId: "G-VYN3VXZ86N"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);