import {
  AllFeatures,
  Feature,
  FeatureDescription,
  FeatureHeader,
  FeaturePlus,
  FeaturesContainer,
  FeaturesHeader
} from "./styles";

function Features() {
  return (
    <FeaturesContainer>
      <FeaturesHeader>Waarom Kouhai Design?</FeaturesHeader>

      <AllFeatures>
        <Feature>
          <FeatureHeader><FeaturePlus>+</FeaturePlus> Customized Solutions</FeatureHeader>
          <FeatureDescription>Wij bieden op maat gemaakte websites die perfect passen bij de identiteit en behoeften van jouw bedrijf, waardoor je je kunt onderscheiden van de concurrentie.</FeatureDescription>
        </Feature>
        <Feature>
          <FeatureHeader><FeaturePlus>+</FeaturePlus> Gebruiksvriendelijkheid</FeatureHeader>
          <FeatureDescription>Onze websites zijn ontworpen met de gebruiker in gedachten, met intuïtieve navigatie en een vriendelijke gebruikerservaring om de interactie met jouw doelgroep te optimaliseren.</FeatureDescription>
        </Feature>
        <Feature>
          <FeatureHeader><FeaturePlus>+</FeaturePlus> Responsief Design</FeatureHeader>
          <FeatureDescription>Wij zorgen ervoor dat jouw website er geweldig uitziet en goed functioneert op alle apparaten, van desktops tot smartphones, waardoor je een breder publiek kunt bereiken.</FeatureDescription>
        </Feature>
        <Feature>
          <FeatureHeader><FeaturePlus>+</FeaturePlus> SEO Geoptimaliseerd</FeatureHeader>
          <FeatureDescription>Door onze aandacht voor zoekmachineoptimalisatie (SEO) wordt jouw website beter vindbaar op Google, wat essentieel is voor het vergroten van de online zichtbaarheid van jouw bedrijf.</FeatureDescription>
        </Feature>
        <Feature>
          <FeatureHeader><FeaturePlus>+</FeaturePlus> Live Updates</FeatureHeader>
          <FeatureDescription>
            Onze dienst stelt klanten in staat om real-time updates van hun website te bekijken op hun eigen toestellen, waardoor ze actief kunnen ervaren en testen hoe de website functioneert, en directe feedback kunnen geven voor een gepersonaliseerde en naadloze gebruikerservaring.</FeatureDescription>
        </Feature>
      </AllFeatures>
    </FeaturesContainer>
  )
}

export default Features;