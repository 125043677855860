import {AllProcesses, Process, ProcessContainer, ProcessesDescription, ProcessesHeader, ProcessTitle} from "./styles";
import Arrow from "./Arrow";

function Processes() {
  return (
    <ProcessContainer>
      <ProcessesHeader>Hoe gaan wij te werk?</ProcessesHeader>
      <ProcessesDescription>
        Bij Kouhai Design maken we het makkelijk in drie stappen! Vertel ons jouw ideeën en visie, en wij gaan aan de slag met het ontwerpen en bouwen van jouw website. Binnen no-time staat jouw gloednieuwe website live!
      </ProcessesDescription>

      <AllProcesses>
        <Process>
          <svg xmlns='http://www.w3.org/2000/svg' width='64px' height='64px' viewBox="0 0 24 24">
            <title>thought_line</title>
            <g id="thought_line" fill='none' fill-rule='evenodd'>
              <path
                d='M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z'/>
              <path fill='#007AFFFF'
                    d='M5 16c.748 0 1.463.226 2.014.64.552.413.986 1.06.986 1.86s-.434 1.447-.986 1.86c-.55.414-1.266.64-2.014.64-.748 0-1.463-.226-2.014-.64C2.434 19.948 2 19.3 2 18.5s.434-1.447.986-1.86C3.536 16.225 4.252 16 5 16Zm0 2c-.357 0-.641.11-.814.24-.123.092-.165.17-.18.218L4 18.5l.007.042c.014.048.056.126.179.219.173.13.457.239.814.239.357 0 .641-.11.814-.24.123-.092.165-.17.18-.218L6 18.5l-.007-.042c-.014-.048-.056-.126-.179-.219C5.641 18.11 5.357 18 5 18Zm7.923-15.115c1.487 0 2.803.727 3.613 1.844a4.462 4.462 0 0 1 4.309 7.344 4.462 4.462 0 0 1-6.296 3.956 4.462 4.462 0 0 1-6.87-1.707A4.462 4.462 0 0 1 8.725 5.83a4.462 4.462 0 0 1 4.197-2.945Zm0 2a2.462 2.462 0 0 0-2.427 2.05 1 1 0 0 1-.888.83l-.146.004a2.462 2.462 0 0 0-.716 4.818 1 1 0 0 1 .667.667 2.463 2.463 0 0 0 4.224.889 1 1 0 0 1 1.333-.166 2.462 2.462 0 0 0 3.867-2.225 1 1 0 0 1 .333-.832 2.462 2.462 0 0 0-2.665-4.078 1 1 0 0 1-1.333-.5 2.462 2.462 0 0 0-2.249-1.457Z'/>
            </g>
          </svg>
          <ProcessTitle>Visie en brainstormen</ProcessTitle>
        </Process>
        <Arrow/>
        <Process>
          <svg xmlns='http://www.w3.org/2000/svg' width='64px' height='64px' viewBox="0 0 24 24">
            <title>laptop_line</title>
            <g id="laptop_line" fill='none' fill-rule='evenodd'>
              <path
                d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z'/>
              <path fill='#007AFFFF'
                    d='M21 19a1 1 0 0 1 .117 1.993L21 21H3a1 1 0 0 1-.117-1.993L3 19h18ZM19 4a2 2 0 0 1 1.995 1.85L21 6v10a2 2 0 0 1-1.85 1.995L19 18H5a2 2 0 0 1-1.995-1.85L3 16V6a2 2 0 0 1 1.85-1.995L5 4h14Zm0 2H5v10h14V6Z'/>
            </g>
          </svg>
          <ProcessTitle>Ontwerpen en uitwerken</ProcessTitle>
        </Process>
        <Arrow/>
        <Process>
          <svg xmlns='http://www.w3.org/2000/svg' width='64px' height='64px' viewBox="0 0 24 24">
            <title>earth_2_line</title>
            <g id="earth_2_line" fill='none' fill-rule='evenodd'>
              <path
                d='M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z'/>
              <path fill='#007AFFFF'
                    d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm1.482 14.94a18.214 18.214 0 0 1-2.964 0c.093.397.197.765.31 1.102.251.755.53 1.293.79 1.622.127.162.23.248.3.29l.051.028.03.008.032-.008.051-.027c.07-.042.173-.129.3-.29.26-.33.539-.868.79-1.623.113-.337.217-.705.31-1.102Zm-8.675-1.435a8.026 8.026 0 0 0 4.441 4.01 10.52 10.52 0 0 1-.318-.84 15.818 15.818 0 0 1-.52-2.033 17.87 17.87 0 0 1-3.603-1.137Zm14.387 0c-1.145.5-2.351.883-3.605 1.137-.14.74-.316 1.423-.52 2.034-.096.29-.202.572-.318.838a8.027 8.027 0 0 0 4.443-4.01Zm-5.218-4.634a15.119 15.119 0 0 1-3.952 0c-.016.368-.024.745-.024 1.129 0 1.024.06 1.999.165 2.896a16.195 16.195 0 0 0 3.67 0C13.94 13.998 14 13.024 14 12c0-.384-.008-.76-.024-1.13ZM4.568 9.032a7.978 7.978 0 0 0-.52 3.856 15.893 15.893 0 0 0 4.067 1.637 27.889 27.889 0 0 1-.074-4.053 14.915 14.915 0 0 1-3.473-1.44Zm14.864 0a14.916 14.916 0 0 1-3.473 1.44 27.879 27.879 0 0 1-.074 4.053 15.892 15.892 0 0 0 4.066-1.637 7.978 7.978 0 0 0-.52-3.855Zm-7.416-5.02-.011-.002-.02.003-.038.015a1.233 1.233 0 0 0-.33.307c-.26.33-.538.868-.79 1.622-.27.808-.488 1.8-.633 2.919a13.123 13.123 0 0 0 3.612 0c-.145-1.12-.364-2.111-.633-2.919-.252-.754-.53-1.293-.79-1.622a1.233 1.233 0 0 0-.3-.29l-.067-.032Zm-2.768.474a8.022 8.022 0 0 0-3.71 2.797c.843.484 1.746.876 2.695 1.163.16-1.164.397-2.223.697-3.122.097-.291.203-.572.318-.838Zm5.504 0c.115.266.22.547.318.838.3.9.537 1.958.697 3.122.949-.287 1.852-.679 2.695-1.163a8.021 8.021 0 0 0-3.71-2.797Z'/>
            </g>
          </svg>
          <ProcessTitle>Live hosten</ProcessTitle>
        </Process>
      </AllProcesses>
    </ProcessContainer>
  );
}

export default Processes;