import styled from "styled-components";

export const ReasonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 50px;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    padding: 32px 16px;
  }
`;

export const ReasonsHeader = styled.h2`
  margin-bottom: 32px;
  font-size: 36px;
`;

export const AllReasons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  
  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
`;

export const Reason = styled.div`
  width: 400px;
  margin: 0 16px;
  padding: 0 32px;
  text-align: center;
  
  display: flex;
  flex-flow: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 16px 0;
    padding: 0;
  }
`;

export const ReasonHeader = styled.h3`
  margin-top: 16px;
  font-size: 20px;
`;

export const ReasonDescription = styled.p`
  margin-top: 8px;
  font-size: 16px;
`;