import {MenuContainer, MenuItem, MenuTitle} from "./styles";
import {scrollTo} from "../../constants/functions";

function Menu({contactRef}) {
  return (
    <MenuContainer>
      <MenuTitle>Kouhai Design</MenuTitle>
      <MenuItem onClick={() => scrollTo(contactRef)}>Contact</MenuItem>
    </MenuContainer>
  );
}

export default Menu;