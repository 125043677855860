import styled from "styled-components";

export const ContactContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  background: black;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const AllContactInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    align-items: center;
  }
`;

export const ContactSectionHeader = styled.h2`
  color: white;
  font-size: 24px;
  margin-bottom: 16px;
`;

export const ContactForm = styled.div`
  margin: 16px;
  display: flex;
  flex-flow: column;
  width: 20%;

  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const FormInput = styled.input`
  margin-bottom: 16px;
  padding: 8px 0;
  font-size: 16px;
  color: white;
  background: none;
  border: none;
  border-bottom: 1px solid white;
  
  &:focus {
    outline: none;
  }
`;

export const FormFreeText = styled.textarea`
  font-size: 16px;
  color: white;
  height: 100px;
  padding: 8px 0;
  background: none;
  border: none;
  border-bottom: 1px solid white;
  resize: none;
  margin-bottom: 20px;
  
  &:focus {
    outline: none;
  }
`;

export const FullWidthButton = styled.button`
  background: #007AFF;
  font-size: 16px;
  margin-top: auto;
  padding: 8px 0;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  color: white;
`;

export const ContactInfo = styled.div`
  margin: 16px;
  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const ContactInfoField = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  
  font-size: 16px;
  color: white;
  
  svg {
    margin-right: 8px;
  }
  
  a {
    color: white;
  }
  
  p {
    max-width: 200px;
  }
`;

export const CopyRights = styled.span`
  color: white;
  font-size: 16px;

  a {
    color: white;
  }

  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;