import styled from "styled-components";

export const IntroContainer = styled.div`
  min-height: 100dvh;
  width: 100%;
  padding: 100px 20% 0 20%;

  background-image: url("https://firebasestorage.googleapis.com/v0/b/kouhaidesign-c8924.appspot.com/o/images%2Fvector_2.webp?alt=media&token=72a32445-871e-491e-ad3a-a28e02b10a2b");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  @media only screen and (max-width: 768px) {
    padding: 100px 10% 0 10%;
  }

  @media only screen and (max-width: 768px) and (orientation: landscape) {
    min-height: 175dvh;
  }
`;

export const IntroHeader = styled.h1`
  color: white;
  font-size: 60px;
  text-align: center;
  margin-bottom: 28px;

  @media only screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

export const IntroDescription = styled.p`
  color: white;
  font-size: 28px;
  margin-bottom: 48px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const IntroButton = styled.button`
  background: #007AFF;
  font-size: 20px;
  padding: 8px 56px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  color: white;
`;

export const IntroImage = styled.img`
  width: 350px;

  @media only screen and (max-width: 768px) {
    width: 250px;
  }
`;