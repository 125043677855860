import styled from "styled-components";

export const PlansContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 100px 0;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    padding: 32px 16px;
  }
`;

export const PlansHeader = styled.h2`
  margin-bottom: 50px;
  font-size: 36px;
`;

export const AllPlans = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
`;

export const Plan = styled.div`
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  
  position: relative;
  width: 350px;
  padding: 50px 16px;
  margin: 0 8px;
  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 8px 0;
  }
`;

export const PlanHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const PlanName = styled.h3`
  font-size: 24px;
`;

export const PlanCost = styled.h3`
  font-size: 20px;
  color: white;
  
  padding: 8px 16px;
  background: #007AFF;
  border-radius: 3px;
  
  position: absolute;
  right: -5px;
  top: -5px;
`;

export const PlanDescription = styled.p`
  margin-bottom: 20px;
`;

export const PlanOptions = styled.div`
  margin-bottom: 30px;
`;

export const PlanFeature = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

export const PlanFeatureText = styled.p`
  font-size: 16px;
  margin-left: 8px;
`;

export const OptionalHeader = styled.h4`
  margin: 16px 0 8px 0;
`;

export const PlanFeatureOptional = styled.span`
  color: lightgrey;
`;