import {
  AllPlans, OptionalHeader,
  Plan,
  PlanCost,
  PlanDescription,
  PlanFeature, PlanFeatureOptional, PlanFeatureText,
  PlanHeader,
  PlanName, PlanOptions,
  PlansContainer,
  PlansHeader
} from "./styles";
import Check from "./Check";
import {plans} from "../../constants/plans";
import {FullWidthButton} from "../Contact/styles";
import {scrollTo} from "../../constants/functions";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../constants/firebase";

function Plans({plansRef, contactRef}) {
  const onPlanClick = () => {
    scrollTo(contactRef);
    logEvent(analytics, "add_to_cart");
  };

  return (
    <PlansContainer ref={plansRef}>
      <PlansHeader>Onze pakketten</PlansHeader>

      <AllPlans>
        {plans.map((plan, i) => (
          <Plan key={`plan-${i+1}`}>
            <PlanCost>{plan.cost}</PlanCost>
            <PlanHeader>
              <PlanName>{plan.name}</PlanName>
            </PlanHeader>
            <PlanDescription>{plan.description}</PlanDescription>

            {plan.features.map((feature, j) => (
              <PlanFeature key={`plan-${i+1}-feature-${j+1}`}>
                <Check />
                <PlanFeatureText>{feature}</PlanFeatureText>
              </PlanFeature>
            ))}

            <OptionalHeader>Optioneel:</OptionalHeader>
            <PlanOptions>
              {plan.optionals.map((option, k) => (
                <PlanFeature key={`plan-${i+1}-option-${k+1}`}>
                  <PlanFeatureOptional>•</PlanFeatureOptional>
                  <PlanFeatureText>{option.name} <b>{option.cost}</b></PlanFeatureText>
                </PlanFeature>
              ))}
            </PlanOptions>

            <FullWidthButton onClick={onPlanClick}>Maak een offerte</FullWidthButton>
          </Plan>
        ))}
      </AllPlans>
    </PlansContainer>
  )
}

export default Plans;