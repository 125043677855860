import Intro from "../Intro";
import Reasons from "../Reasons";
import Features from "../Features";
import Plans from "../Plans";
import {useEffect, useRef} from "react";
import Processes from "../Processes";
import Contact from "../Contact";
import Menu from "../Menu";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../constants/firebase";

function Main() {
  const plansRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    logEvent(analytics, "site_visited");
  }, []);

  return (
    <>
      <Menu contactRef={contactRef} />
      <Intro plansRef={plansRef} />
      <Reasons />
      <Features />
      <Plans plansRef={plansRef} contactRef={contactRef} />
      <Processes />
      <Contact contactRef={contactRef} />
    </>
  )
}

export default Main;