import {IntroButton, IntroContainer, IntroDescription, IntroHeader, IntroImage} from "./styles";
import {scrollTo} from "../../constants/functions";

function Intro({plansRef}) {
  return (
    <IntroContainer>
      <IntroHeader>Boost je start-up online</IntroHeader>
      <IntroDescription>Wij maken websites voor groeiende ondernemingen</IntroDescription>
      <IntroButton onClick={() => scrollTo(plansRef)}>Aan de slag</IntroButton>
      <IntroImage
        src="https://firebasestorage.googleapis.com/v0/b/kouhaidesign-c8924.appspot.com/o/images%2Flaptop.webp?alt=media&token=2f1aa735-0eec-480f-9c09-3f6d295031e5"
        draggable={false}
        alt="person-on-laptop"
      />
    </IntroContainer>
  )
}

export default Intro;